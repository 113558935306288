import * as Constants from './../utils/constants';
import { Enums } from './../../shared/Enums';
import { StateService } from '@uirouter/core';

export interface IAuthenticationService {
    /**
        * Indicates whether users are to be authenticated server side or at an Identity Provider
        */
    useSaml2: boolean;
    useLocalLogin: boolean;
    useAuth0: boolean;
    loginBusy: boolean;
    loginIncorrect: boolean;
    loginFailed: boolean;
    login(username, password);
    logoff(resolve: Function, reject: Function): void;
    disconnect(): void;
    loginWithAuth0Token(authorizationCode: string, redirectUri: string);
    redirectToLoginPage();
    scheduleRefreshToken();
    tryLoginWithRefreshToken();
}

interface ICurrentUserStorage {
    username: string;
    userId: string;
    token: string;
    idToken?: string;
    expires?: string;
}

interface IRefreshTokenStorage {
    token: string;
    refreshMoment: string;
}

export var authenticationService = [
    "$http", "$location", "$httpParamSerializer", "$state", "$timeout", "$window", "angularAuth0", "notificationService", "userService", "SSOLoginData",
    function (
        $http: ng.IHttpService,
        $location: ng.ILocationService,
        $httpParamSerializer: ng.IHttpParamSerializer,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $window: ng.IWindowService, angularAuth0, notificationService, userService, SSOLoginData) {
        var svc = this;

        svc.loginIncorrect = false;
        svc.loginBusy = false;
        svc.loginFailed = false;
        svc.useSaml2 = false;
        svc.useLocalLogin = false;
        svc.useAuth0 = false;
        svc.isRefreshing = false;

        svc.currentScheduledRefreshTokenTimer = null;
        svc.currentScheduledRefreshToken = "";

        svc.login = function (userName, password) {
            notificationService.disconnect(); // if there already was a login before, close the existing websocket
            var data = "grant_type=password&username=" + userName + "&password=" + encodeURIComponent(password);
            return $http.post("token", data) // Request a token with the provided username & password
                .then(this.handleTokenResponse);
        };

        svc.loginWithAuth0Token = function (authorizationCode: string, redirectUri: string) {
            return this.getTokenWithAuth0Token(Constants.auth0ClientId, authorizationCode, redirectUri).then(this.handleTokenResponse,
                function (response) {
                    console.log(response);
                    throw Error(response.data.error);
                });
        };

        svc.getTokenWithAuth0Token = function (clientId: string, authorizationCode: string, redirectUri: string) {
            return $http.post("Token",
                $httpParamSerializer({
                    grant_type: "authorization_code",
                    "client_id": clientId,
                    "client_secret": "",
                    "code": authorizationCode,
                    "redirect_uri": redirectUri
                }),
                {
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded"
                    }
                });
        };

        svc.getTokenWithRefreshToken = function (refreshtoken) {
            return $http.post("Token",
                $httpParamSerializer({
                    grant_type: "refresh_token",
                    refresh_token: refreshtoken
                }),
                {
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded"
                    }
                });
        }

        svc.currentUserInStorage = function (): ICurrentUserStorage {
            return $window.localStorage.getItem(this.currentUserStorageKey) === undefined ? undefined : JSON.parse($window.localStorage.getItem(Constants.currentUserStorageKey))
        }

        svc.refreshTokenInStorage = function (): IRefreshTokenStorage {
            return $window.localStorage.getItem(this.refreshTokenStorageKey) === undefined ? undefined : JSON.parse($window.localStorage.getItem(Constants.refreshTokenStorageKey))
        }

        // starts a timer to retrieve a new refresh token
        svc.scheduleRefreshToken = function () {
            const refreshToken: IRefreshTokenStorage = svc.refreshTokenInStorage();
            if (refreshToken && svc.currentScheduledRefreshToken !== refreshToken.token) {
                const timeExpirationInMs = parseInt(refreshToken.refreshMoment) - new Date().getTime() - 30 * 1000;

                if (!isNaN(timeExpirationInMs) && timeExpirationInMs > 0) {
                    svc.currentScheduledRefreshTokenTimer = $timeout(svc.tryLoginWithRefreshToken.bind(this), timeExpirationInMs);
                    svc.currentScheduledRefreshToken = refreshToken;
                }
            }
        }

        // store access token, refresh token, and expiration time of the refresh token, and starts timer to get a new refresh token
        svc.storeAccessAndRefreshToken = function (response) {
            const currentUser: ICurrentUserStorage = {
                token: response.data.access_token,
                userId: response.data.userId,
                username: response.data.userName,
                expires: (new Date().getTime() + response.data.expires_in * 1000).toString()
            };

            $window.localStorage.setItem(Constants.currentUserStorageKey, JSON.stringify(currentUser));

            const refreshToken: IRefreshTokenStorage = {
                token: response.data.refresh_token,
                refreshMoment: (new Date().getTime() + response.data.expires_in * 1000).toString()
            };

            $window.localStorage.setItem(Constants.refreshTokenStorageKey, JSON.stringify(refreshToken));

            svc.scheduleRefreshToken();
        }

        // attempt to get a new access token and refresh token using the current refresh token
        svc.tryLoginWithRefreshToken = function () {
            const refreshToken: IRefreshTokenStorage = svc.refreshTokenInStorage();
            if (refreshToken && !svc.isRefreshing) {
                svc.isRefreshing = true;

                svc.getTokenWithRefreshToken(refreshToken.token).then(function (response) {
                    svc.isRefreshing = false;
                    // login successful if there's a token in the response
                    var accessToken = response.data.access_token;
                    if (accessToken) {
                        console.log("Authentication: New refresh token successfully received");

                        svc.storeAccessAndRefreshToken(response);
                    } else {
                        console.error("Authentication: No token was received", response);
                        svc.logoff(null, null);
                    }
                },
                    function (response) {
                        console.error("Authentication: Error login with refresh token", response);
                        svc.logoff(null, null);
                    });
            } else if (!refreshToken) {
                console.error("Authentication: No refresh token was found");
                svc.logoff(null, null);
            }
        }

        svc.handleTokenResponse = function (response) {
            // Success: store the tokens
            svc.storeAccessAndRefreshToken(response);

            return $http.get("api/GetUserInfo")
                .then(function (userInfo) {
                    // set userInfo in browser & connect websocket
                    response.data = userInfo.data;
                    svc.reconnect(userInfo.data);
                    return response.data;
                });
        };

        svc.logoff = function (resolve, reject) {
            notificationService.disconnect(); // first close the websocket
            return $http.post("Auth/Logout", {})
                .then(function () {
                    $window.localStorage.removeItem(Constants.currentUserStorageKey);
                    $window.localStorage.removeItem(Constants.refreshTokenStorageKey);
                    SSOLoginData.ssoLoginAttempted = false;
                    userService.logoff();

                    const portPostfix = $location.protocol() === "http" && $location.port() === 80 ||
                        $location.protocol() === "https" && $location.port() === 443
                        ? ""
                        : `:${$location.port()}`;

                    const returnToUrl = $location.path().includes('angularomrp') ? "" :
                        $location.protocol() + "://" + $location.host() + portPostfix + "/";

                    if (svc.useAuth0) {
                        angularAuth0.logout({ returnTo: returnToUrl });
                    } else {
                        $window.location.href = returnToUrl;
                    }

                    if (resolve != null) resolve();
                }, function () {
                    if (reject != null) reject();
                });
        };

        // close the websocket connection
        svc.disconnect = function () {
            notificationService.disconnect();
        };

        // set authentication variables and start websocket connection
        svc.reconnect = function (userInfo) {
            if (userInfo == null ||
                ((userInfo.id == null || userInfo.id === 0) &&
                    (userInfo.resourceId == null || userInfo.resourceId === 0))) return false;
            if (userInfo.id == null) userInfo.id = 0; // the user service assumes this id field exist
            userService.userInfo = userInfo;
            userService.isAuthenticated = true;
            userService.isFullUser =
                userInfo.id >
                0; // id must be set and greater than zero for the user to be a full user, else it is only a resource
            svc.loginIncorrect = false;
            svc.loginFailed = false;

            notificationService.disconnect();
            if (userService.isFullUser) $timeout(function () { notificationService.connect(); }, 500);
            return true;
        };

        svc.getAuthMode = function () {
            return $http.get("api/AuthenticationMode")
                .then(function (response) {
                    return response.data;
                },
                    function () {
                        return Enums.AuthMode.Auth0;
                    });
        };
        svc.getAuthMode().then(function (result) {
            if (result === Enums.AuthMode.Saml2) {
                svc.useSaml2 = true;
            } else if (result === Enums.AuthMode.LocalLogin) {
                svc.useLocalLogin = true;
            } else if (result === Enums.AuthMode.Auth0) {
                svc.useAuth0 = true;
            }
        });

        svc.redirectToLoginPage = function () {
            this.getAuthMode().then(function (authMode) {
                if (authMode === Enums.AuthMode.Auth0) {
                    angularAuth0.authorize();
                } else {
                    $state.go("login");
                }
            });
        };
    }
];